import axios from "axios";
import moment from "moment";
import { toast } from 'react-toastify';
import { getProfileInfo } from "./utils";

const environment = process.env.REACT_APP_ENVIRONMENT

// User authentication
export const STORAGE_KEY = "menu-data";
export const isAuthenticated = () => localStorage.getItem(STORAGE_KEY) !== null;
export const getToken = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  if (menuData) {
    return menuData.token
  } else {
    return null
  }

}

export const getMenuData = () => {
  let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  return menuData
}

export const getCacheData = (component) => {
  switch (component) {
    case 'YTPlaylist':
      return JSON.parse(localStorage.getItem('YTPlaylist'))
    case 'LiveList':
      return JSON.parse(localStorage.getItem('LiveList'))
    default:
      return false
  }
}

export const setCacheData = (data, component) => {
  switch (component) {
    case 'YTPlaylist':
      return localStorage.setItem('YTPlaylist', JSON.stringify(data));
    case 'LiveList':
      return localStorage.setItem('LiveList', JSON.stringify(data));
    default:
      return false
  }
};

export const setLoginData = (data) => {
  const date = moment().utc().format('YYYY-MM-DD')
  Object.assign(data.loginInfo, { lastLogin: date })
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export const api = axios.create({
  baseURL: environment,
  timeout: 10000,
});

api.interceptors.request.use(async config => {
  // Check internet connection
  if (!window.navigator.onLine) {
    // DOC: https://fkhadra.github.io/react-toastify/introduction/
    toast.error('Parece que você está sem conexão com a internet.', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // Add token to request

  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // console("COnfig:"+ config)
  return config;
});

api.interceptors.response.use( response => {
  
  // Redirect to login if token is expired
  const menuData = getMenuData();

  if(menuData) {
    const currentDate = moment().utc().format('YYYY-MM-DD')
    const lastLoginDate = menuData.loginInfo?.lastLogin

    if(currentDate > lastLoginDate) {renewlogin()}
  }

  // Renew Token
  if(response.data.hasOwnProperty('newToken')){
    updateToken(response.data.newToken)
  }

  return response

}, (error => {

    // Redirect on invalid token
    if(error?.response?.status === 401) {
      redirectInInvalidToken();
    }
  })
)

const redirectInInvalidToken = () => {
  localStorage.removeItem(STORAGE_KEY);
  window.location.replace('/');
}

// Renova o Login
export async function renewlogin() {
  
  const profile = getProfileInfo();
  const data = {
    idUser: profile.idUser,
    idAppType: profile.idAppType
  }

  const response = await api({
    method: "POST",
    url: "/login/renewlogin",
    data: data,
  });
  
  const lastAccess = response.data?.loginInfo?.lastAccess?.slice(0, 10)
  if(response.data.success === true){
    setLoginData({...response.data, loginInfo: { ...response.data.loginInfo, lastLogin: lastAccess } })
  }
  
  return response;
}

const updateToken = (newToken) => {
  let menuData = getMenuData()
  menuData.token = newToken
  setLoginData(menuData)
}

// Lista de conteudo usando filtro
export function listFilter(id, data) {
  return api({
    method: 'POST',
    url: `/content/listfilter/${id}`,
    data: data
  })
}

// Seleciona o appType de acordo com o endereco
export function appDetails(bucket) {
  return axios.post(
    `${environment}/app/details/`,
    { "bucket": bucket },
  )
}

// Autenticação do usuario
export function userLogin(login, password, appType) {
  return api({
    method: 'POST',
    url: '/login',
    data: {
      "email": `${login}`,
      "password": `${password}`,
      "idAppType": appType
    }
  })
}

// Retorna o conteúdo do aluno
export function getContent(sectionId) {
  let appDetails = JSON.parse(localStorage.getItem('appDetails'))
  return api({
    method: 'POST',
    url: '/content',
    data: {
      "section": `${sectionId}`,
      "channel": 1,
      "user": 1,
      "company": 1,
      "appType": appDetails.idAppType,
      "app": 1
    }
  })
}

// Envia o email para recuperacao de senha
export function sendEmailPassword(email) {
  return axios({
    method: 'POST',
    //url: 'http://localhost:3333',
    url: `${environment}/login/forgot`,
    data: { "email": `${email}` }
  })
}

// Envia o rating escolhido pelo usuario
export function setRating(data) {
  return api({
    method: 'POST',
    url: `/ranking/vote/`,
    data: data
  })
}


// Retorna o conteúdo do aluno pelo ID
export function contentList(id) {
  return api({
    method: 'GET',
    url: `/content/list/${id}`
  })
}

// Retorna a lista de usuários
export function searchUser() {
  return api({
    method: 'GET',
    url: `/users/searchuser`
  })
}

// Retorna a lista de usuários
export function usersList() {
  return api({
    method: 'GET',
    url: `/users`
  })
}

// Retorna o conteúdo do aluno pelo ID
export function findContent(id) {
  return api({
    method: 'GET',
    url: `/content/find/${id}`

  })
}

// Retorna os dados do aluno pelo ID
export function getUserInfo(id) {
  return api({
    method: 'GET',
    url: `/users/${id}`
  })
}

// Edita as informações do aluno
export function setUserInfo(id, data) {
  return api({
    method: 'PUT',
    url: `/users/${id}`,
    data: data,
    validateStatus: () => true
  })
}

// Edita o campo especifico de informacao do aluno
export function setUserField(id, data) {
  return api({
    method: 'PUT',
    url: `/users/editField/${id}`,
    data: data
  })
}

// Edita as informações do aluno
export function setUserAgreement(id, data) {
  return api({
    method: 'PUT',
    url: `/users/agreement/${id} `,
    data: data
  })
}

// SSO NexiMobi
export function getAssessment(url, data) {
  return api({
    method: 'POST',
    url: url,
    data: data
  })
}

// Salva dados da sala do chat
export function chatSaveRoom(roomId, data) {
  return api({
    method: 'POST',
    url: `/chat/savemessageroom/${roomId}`,
    data: data
  })
}

// Retorna os dados de uma sala do chat
export function chatLoadRoom(roomId) {
  return api({
    method: 'GET',
    url: `/chat/loadroom/${roomId}`
  })
}

// Retorna a lista de salas do chat
export function chatListRooms() {
  return api({
    method: 'GET',
    url: `/chat/listrooms/`
  })
}

// Retorna lista de steps de jornada IMPACT
export function getImpactMenu(id) {
  return api({
    method: 'GET',
    url: `/content/impactmenu/${id}`
  })
}

export function initTracking(navItem, navType) {
  return api({
    method: 'POST',
    url: '/tracking/enter',
    data: {
      "idNavigationItem": `${navItem}`,
      "idNavigationType": `${navType}`
    }
  })
}

export function recordTracking(timeStart, navItem, navType, idSection = null, timeExecuted = 0){
  return api({
    method: 'POST',
    url: '/tracking/exit',
    data: {
      timeStart: timeStart,
      idNavigationItem: navItem,
      idNavigationType: navType,
      idSection: idSection,
      timeExecuted: timeExecuted,
    }
  })
}

export function uploadImageApi(data) {
  data.append("token", getToken());
  return axios.post("https://upload.bedigital.net.br/uploads/file", data, {
    headers: {
      "Content-Type": `multipart/form-data`,
      "accept": 'application/json',
    },
  });
}
  
export function getProductsLojinha(searchText){
  return api({
    method: 'POST',
    url: '/store/offers',
    data: { searchText: searchText}
  });
}
  
export function getPurchasedProductsLojinha(searchText){
  return api({
    method: 'POST',
    url: '/store/purchased',
    data: { searchText: searchText}
  });
}

export function getCartItensLojinha() {
  return api({
    method: 'GET',
    url: '/store/shoppingcart'
  });
}

export function addProductsToCartLojinha(id) {
  return api({
    method: 'POST',
    url: '/store/addtocart',
    data: { idOffer: id }
  });
}

export function removeProductsToCartLojinha(id) {
  return api({
    method: 'POST',
    url: '/store/removefromcart',
    data: { idOffer: id }
  });
}

export function buyLojinha(data) {
  return api({
    method: 'POST',
    url: '/store/buy',
    data: data
  });
}

export function getTotalUserPoints() {
  return api({
    method: 'GET',
    url: '/campaign/totaluserpoints',
  });
}

export function getListCampaigns() {
  return api({
    method: 'GET',
    url: '/campaign/listcampaigns',
  });
}

export function listcampaignuserpoints(data) {
  return api({
    method: 'POST',
    url: '/campaign/listcampaignuserpoints',
    data
  });
}
export function getListCampaignsDetail(id) {
  return api({
    method: 'GET',
    url: `/campaign/listcampaigndetail/${id}`,
  });
}
export function getCampaignsEvents() {
  return api({
    method: 'GET',
    url: `/campaign/listevents/`,
  });
}





export function getSchedules(setListschedules=null){
  return api({
    method: "GET",
    url: "/schedule/listschedules",
  }).then((response) => {
      if (response.data.success === true) {
        if( setListschedules != null ){
          setListschedules(response.data.scheduleResult.schedules);
        }
        return response.data.scheduleResult.schedules;        
      }
      if( setListschedules != null ){
        setListschedules([]);
      }
      return [];
    })
    .catch((err) => {
      if( setListschedules != null ){
        setListschedules([]);
      }
      return [];      
      throw err;
    });
}

export function getSchedulesTypes(id){
  return api({
    method: "GET",
    url: "/schedule/listschedulestype",
  })
  .then((response) => {
    if (response.data.success === true) {
      return response.data.schedulesType;
    }
    return false;
  })
  .catch((err) => {
    return false;
    throw err;
  });
}

export function getGroupList(){
  return api.get(`/group/list`)
    .then((response) => {
        if (response.data.success === true) {
          return response.data.groups
        }

        return [];
    })
    .catch((err) => {
      return [];
      throw err;
    });
}

export function updateYLDuration(idContent, duration) {
  const data = {
    "idContentItem": idContent,
    "duration": duration
  }

  return api({
    method: 'POST',
    url: '/content/updateduration',
    data
  }).then((response) => {
    return response
  });
}

export function getUploadList(idSection){
  return api.get(`/userupload/list/${idSection}`);
}

export function contentItensGroups(id,data='') {
  return api({
    method: "GET",
    url: `/content/contentgroup/${id+data}`,
    data: data
  })
}

export function getListContentChild(id) {
  return api({
    method: 'GET',
    url: `/content/listcontentchild/${id}`,
  });
}

export default api;
