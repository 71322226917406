import React,{useState,useEffect} from 'react';
import { ReactSVG } from 'react-svg'
import Cup from '../../assets/images/championsCup.svg';
import './index.scss'
import { getTotalUserPoints } from '../../services/api'
import FlipNumbers from 'react-flip-numbers';
import { connect } from 'react-redux';
import { updatePoints } from '../../actions';
import { getGamigication } from '../../services/utils';

const  _PointsHeader = ({currentPoints, action={}, backgroundColor = '#FFF'}) => {
    const [isGamification, setGamification] = useState()
    const [animationPoints, setPointsAnimations] = useState(currentPoints);
    
    const _getTotalUserPoints = () => {
        getTotalUserPoints().then(res => {
            if(res?.data?.success ===  true) {
                const userPoints = res.data.userpoints
                setPointsAnimations(userPoints)
            } else {
                console.log('getTotalUserPoints error: ', res)
            }
        }).catch(err => {
            console.log('getTotalUserPoints error: ', err)
        })
    }

    useEffect(() => {
        if(animationPoints === 0 && getGamigication()) {
            _getTotalUserPoints()
        }
        setGamification(getGamigication())
    },[])

    useEffect(() => {
        if (currentPoints) {
            setPointsAnimations(currentPoints)
        }
    }, [currentPoints])
   
    return(
        <>
            {isGamification ? 
                (
                    <div className="pointing" onClick={action} style={{boxShadow: `inset 4px 4px 16px 14px ${backgroundColor}`}}>
                        <ReactSVG
                            beforeInjection={(svg) => {
                                svg.setAttribute('style', `width: 15px; height: 15px; margin-bottom: 5px; fill: 'white'`)
                                }}
                            src={Cup}  alt="cup" 
                        />
                        <div className="div-points">
                            <div className="div-points">
                                <FlipNumbers height={10} width={6} color="white" numberStyle={{fontWeight:700, fontSize:11}} background="transparent" play  numbers={`${animationPoints}`} />
                            <span style={{margin:2, marginTop: 3}} >pts</span>
                            </div>
                        
                        </div>
                    
                    </div>
                ) : (
                    null
                )
            }
        </>
    )

}

const  _PointsFooter = ({ currentPoints, points = 0, action={},text= 'pts', style = {}}  ) => {
    const [animationPoints, setPointsAnimations] = useState(currentPoints);

    const _getTotalUserPoints = () => {
        getTotalUserPoints().then(res => {
            if(res?.data?.success ===  true) {
                const userPoints = res.data.userpoints
                setPointsAnimations(userPoints)
            } else {
                console.log('getTotalUserPoints error: ', res)
            }
        }).catch(err => {
            console.log('getTotalUserPoints error: ', err)
        })
    }

    useEffect(() => {
        if(animationPoints === 0 && getGamigication()) {
            _getTotalUserPoints()
        }
    },[])
    
    return(
        <>
            { getGamigication() ? 
                (
                    <div className="coins" >
                        <ReactSVG
                            beforeInjection={(svg) => {
                                svg.setAttribute('style', `width: 14px; height: 14px; margin-bottom:3px; fill: 'white'`)
                                }}
                            src={Cup}  alt="cup" 
                        />
                        <div className="div-points" >
                            <span style={style}>{animationPoints} {text}</span>
                        </div>
                  
                    </div>
                ) 
                : (
                    null
                )
            }
        </>
     
    )

}
const mapDispatchToProps = (dispatch) => ({
    updatePoints: (item) => dispatch(updatePoints(item)),
});
const mapStateToProps = store => ({
    currentPoints: store.pointsState.points,
    scorePoints: store.pointsState.scorePoints,
    change: store.pointsState.change
});
const PointsHeader =  connect(mapStateToProps, mapDispatchToProps)(_PointsHeader);
const PointsFooter =  connect(mapStateToProps, mapDispatchToProps)(_PointsFooter);

export {PointsHeader,PointsFooter};