import React, { useState, useEffect } from 'react';
import { getListCampaigns, listcampaignuserpoints, getListCampaignsDetail, getCampaignsEvents } from '../../services/api';
import moment from 'moment';
import "moment/locale/pt-br";
import { getColors } from '../../services/utils';
import { ReactSVG } from 'react-svg'
import './index.scss';
import Cup from '../../assets/images/championsCup.svg';
import { FormControl, Select, makeStyles, MenuItem } from '@material-ui/core';
import * as XLSX from "xlsx";
import xlxsIcon from '../../assets/images/xlsx-icon.png'


const useStyles = makeStyles(() => ({
    formControl: {
        width: 200,
        height: 20,
        marginBottom: 10,
        marginRight: 25,
    },
    formText: {
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 600,
        color: "#666",
    }
}));

const PointDetail = ({ id, setTotalPoints, exist = false }) => {
    const classes = useStyles();
    const [loadingUserPoints, setLoadingUserPoints] = useState(false);
    const [loadingEventsPoints, setLoadingEventsPoints] = useState(false);
    const [pointsUser, setPointsUser] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventPoints, setEventPoints] = useState([]);
    const [_exist, setExist] = useState(exist);
    const [more, setMore] = useState(2);
    const [campaignList, setCampaignList] = useState([]);
    const [firstCampaign, setFirstCampaign] = useState("");

    const handleChangeCampaign = (event) => {
        const campaignId = event.target.value

        if (campaignId === 0) {
            listAllCampaignEvents()
        } else {
            _listCampaignUserPoints(campaignId)
            _getListCampaignsDetail(campaignId)
        }
    };

    useEffect(() => {
        if (id && events.length && exist) {
            _getListCampaigns()
        }
    }, [events])

    useEffect(() => {
        getCampaignsEvents().then(res => {
            if (res.data.success) {
                setEvents(res.data.event)
            } else {
                setEvents([])
            }

        }).catch(res => {
            setEvents([])
        })
    }, [])

    const downloadExcel = (data) => {
        if (data) {
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "MinhasAtividades.xlsx");
        }
    }

    const _getListCampaigns = () => {
        getListCampaigns().then(async res => {
            if (res.data.success === true) {
                const activeCampaigns = res.data.campaigns.filter(campaign => campaign.status === 1)
                setCampaignList(activeCampaigns)
                const firstCampaignId = activeCampaigns?.[0]?.idgamification_campaign

                setFirstCampaign(firstCampaignId)
                _listCampaignUserPoints(firstCampaignId)
                _getListCampaignsDetail(firstCampaignId)
                setExist(true)
            }
        }).catch(() => {
            setExist(false)
        })
    }

    const _listCampaignUserPoints = (idCampaign) => {
        setLoadingUserPoints(true)
        setPointsUser([])
        setTotalPoints(0)
        listcampaignuserpoints({ idCampaign, idUser: id }).then(res => {
            if (res.data.success === true) {
                let _pointsUser = []
                let _totalPoints = 0
                res.data.campaignuserpoints.forEach((item) => {
                    _pointsUser = [
                        ..._pointsUser,
                        {
                            contentitemtext: item.contentitemtext,
                            event: item.event,
                            date: moment(item.eventDate).format("lll"),
                            points: item.points
                        }
                    ]
                    _totalPoints += parseInt(item.points);
                })
                setPointsUser(_pointsUser);
                setTotalPoints(_totalPoints);
            }
        }).catch(() => {
            setPointsUser([])
            setTotalPoints(0)
        }).finally(() => {
            setLoadingUserPoints(false)
        })
    }

    const _getListCampaignsDetail = (idCampaign) => {
        setLoadingEventsPoints(true)
        getListCampaignsDetail(idCampaign).then(async res => {
            let _eventPoints = []
            if (res.data.success === true) {
                res.data.events.forEach(item => {
                    let data = events.find(e => e.idgamification_event === item.idEvent);
                    if (data) {
                        const eventItem = { name: data.event, points: item.points, id: data.idgamification_event }
                        _eventPoints.push(eventItem)
                    }
                })
                setEventPoints(_eventPoints);
            }
        }).catch(err => {
            setEventPoints([]);
        }).finally(() => {
            setLoadingEventsPoints(false)
        })
    }

    const listAllCampaignEvents = () => {
        if (id && events.length && exist) {
            setLoadingUserPoints(true);
            setLoadingEventsPoints(true);
            getListCampaigns().then(async res => {
                if (res.data.success === true) {
                    const activeCampaigns = res.data.campaigns.filter(campaign => campaign.status === 1)
                    setCampaignList(activeCampaigns)
                    let _pointsUser = [];
                    let _totalPoints = 0;
                    let _eventPoints = [];
                    await res.data.campaigns.forEach(async (elem) => {
                        if (elem.status === 1) {
                            await listcampaignuserpoints({ idCampaign: elem.idgamification_campaign, idUser: id }).then(res => {
                                if (res.data.success === true) {
                                    res.data.campaignuserpoints.forEach((item) => {
                                        _pointsUser = [..._pointsUser,
                                        {
                                            contentitemtext: item.contentitemtext,
                                            event: item.event,
                                            date: moment(item.eventDate).format("lll"),
                                            points: item.points
                                        }
                                        ]
                                        _totalPoints += parseInt(item.points);
                                    })
                                    setPointsUser(_pointsUser);
                                    setTotalPoints(_totalPoints);
                                }
                            }).catch(() => {
                                setPointsUser([])
                                setTotalPoints(0)
                            })
                            await getListCampaignsDetail(elem.idgamification_campaign).then(async res => {
                                if (res.data.success === true) {
                                    await res.data.events.forEach(item => {
                                        let data = events.find(e => e.idgamification_event === item.idEvent);
                                        if (data) {
                                            _eventPoints = [..._eventPoints, { name: data.event, points: item.points, id: data.idgamification_event }]
                                        }
                                    })
                                    setEventPoints(_eventPoints);
                                }
                            }).catch(() => {
                                setEventPoints([]);
                            })
                        }
                    })
                    setLoadingUserPoints(false);
                    setLoadingEventsPoints(false);
                    setExist(true)
                }
            }).catch(e => {
                setExist(false)
            })
        }
    }

    return (
        <>
            {
                _exist ? (
                    <div>
                        <div className="profile-card-points-header section">
                            <span className="title-profile-points">
                                Meus Pontos
                            </span>
                            <div style={{ display: 'flex' }}>
                                <span className="title-profile-campaign">
                                    Campanha
                                </span>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <Select
                                        onChange={handleChangeCampaign}
                                        label="Campanhas"
                                        inputProps={{
                                            name: 'campaign',
                                            id: 'outlined-campaign-native-simple',
                                        }}
                                        autoWidth
                                        className={classes.formText}
                                        defaultValue={firstCampaign}
                                    >
                                        <MenuItem value={0}>
                                            Todos
                                        </MenuItem>
                                        {
                                            campaignList.map(campaignItem => (
                                                <MenuItem value={campaignItem.idgamification_campaign}>
                                                    {campaignItem.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="point-section">
                            <section className="card-points">
                                <div className="profile-card column" style={{ marginTop: 0 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span className="subtitle-profile-edit">
                                            Minhas atividades
                                        </span>
                                        {
                                            pointsUser.length > 0 &&
                                            <img
                                                className="profile-download-excel"
                                                src={xlxsIcon}
                                                alt="download-spreadsheet"
                                                onClick={() => downloadExcel(pointsUser)}
                                            />
                                        }
                                    </div>
                                    {
                                        !loadingUserPoints && pointsUser.length === 0 ? (
                                            <div style={{ color: "#999", display: "flex", justifyContent: "center", margin: '15px 0px' }}> 
                                                Nenhuma atividade encontrada. 
                                            </div>
                                        )
                                            : (
                                                pointsUser.slice(0, more).map((elem, index) => {
                                                    return (
                                                        <div key={`activities${index}`} className="subtitle-profile-edit" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12 }}>{elem.contentitemtext}</span>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <span style={{ fontSize: 12, color: "#999" }}>
                                                                            {elem.event} - {elem.date}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <span style={{ fontWeight: 700 }}>{`${elem.points}pts`}</span>
                                                            </div>
                                                            <div className="hr-profile" />
                                                        </div>
                                                    )
                                                })
                                            )
                                    }
                                    {loadingUserPoints && <div className="loader" style={{ display: 'inline-block', height: 50, width: 50, position: 'relative', right: 0, left: "45%" }} />}
                                </div>
                                {
                                    pointsUser.length && more < pointsUser.length ?
                                        (
                                            <div className="show-more-profile" onClick={() => setMore(more + 5)}>
                                                <span className="subtitle-profile-edit" style={{ fontWeight: 600, color: getColors('menu').secondColor }}>Mostrar mais</span>
                                            </div>
                                        ) : (
                                            null
                                        )
                                }
                            </section>
                            <section className="card-points">
                                <div className="profile-card column second-section" style={{ marginTop: 0 }}>
                                    <span className="subtitle-profile-edit" style={{ marginLeft: 0 }}>
                                        Como você ganha pontos
                                    </span>
                                    {!loadingEventsPoints ?
                                        (
                                            <div className='table-profile'>
                                                {
                                                    eventPoints.map((elem, index) => {
                                                        return (
                                                            <div key={`howto${index}`} className="tr-profile" style={{ borderTop: index === 0 ? 0 : "1px solid #DCDCDC" }}>
                                                                <div className="td-icon-profile">
                                                                    <ReactSVG
                                                                        beforeInjection={(svg) => {
                                                                            svg.setAttribute('style', `width: 15px; height: 15px;fill: 'white'`)
                                                                        }}
                                                                        src={Cup} alt="cup"
                                                                    />
                                                                    <span className="subtitle-profile-edit" style={{ margin: 0, marginLeft: 10, fontSize: 12 }}>{elem.name}</span>
                                                                </div>

                                                                <span className="td-points-profile subtitle-profile-edit" style={{ fontSize: 12 }}>
                                                                    {`${elem.points}pts`}
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        ) : (
                                            <div className="loader" style={{ display: 'inline-block', height: 50, width: 50, position: 'relative', right: 0, left: "45%" }} />
                                        )
                                    }
                                </div>
                            </section>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}

export default PointDetail;