import React from "react";
import CompleteRating from "../CompleteRating";
import "../../index.css";
import "../../assets/css/ModalPlayer.css";
import moment from "moment";
import { getDesign, getFooterSize, getGamigication } from "../../services/utils";
import { updateFooterStatus, updateHeaderStatus, updatePoints } from "../../actions";
import { connect } from "react-redux";
import style from './style.module.scss';
import PodcastList from "../PodcastList";
import { recordTracking } from '../../services/api';
import ModalContinueMedia from '../ModalContinueMedia';
import AudioInfo from "./Component/AudioInfo";
import ReactPlayer from 'react-player'
import IdleTimer from "react-idle-timer";
import _ from 'lodash';

class ContentAudio extends React.Component {
  constructor(props) {
    super(props);
		this.handleOnActive = this.handleOnActive.bind(this);
		this.handleOnIdle = this.handleOnIdle.bind(this);
		this.idleTimerRef = null;
    this.state = {
      timeRefresh: 300000, // 300000ms = 5min
      isOpen: true,
      key: 0,
      descriptiion: "",
      image: "",
      contentLocation: "",
      url: null,
      playing: false,
      controls: false,
      played: 0,
      duration: 0,
      isDesktop: false,
      idContentItem: 0,
      timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      isLoadingData: true,
      noData: false,
      lastTime: 0,
      arrayTime: [],
      timeExecuted: 0,
      modal: false,
      shouldRecordTracking: true
    };
  }

	handleOnActive() {
		this.setState({ ignoreRecordTracking: false });
	}	
  
  handleOnIdle() {
      if(this.state.playing && this.idleTimerRef) {
        this.idleTimerRef.reset()
      } else {
        this.exit();
      }
	}

	resetTime() {
		if (this.idleTimerRef) {
			this.idleTimerRef.reset();
		}
	}

  startFromBeggining(){
    this.player.seekTo(0)
    this.setState({ modal: false });
  }

  continueFromLastTime(){
    this.setState({ modal: false, playing: true });
  }

  currentTime = () => {
    return this.player?.getCurrentTime()
  }

  handlePause = () => {
    this.setState({ playing: false });
  };

  handlePlay = () => {
    this.setState({ playing: true });
    this.trackStart(this);
  };

  componentDidMount() {
		this.setState({ shouldRecordTracking: true })
    if( !(this.props.data.lastPosition === null || this.props.data.lastPosition === 0) ){
      // this.audioplay.current.audio.current.currentTime = this.props.data.lastPosition;
      this.setState({ modal: true });
    }
  }

  componentWillUnmount() {
    // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audio dentro do html(frame))
    this.exit()
  }

  exit() {
    if (this.state.shouldRecordTracking) {
      const timeStart = this.state.timeStart;
      const navItem = this.props.data.idContentItem;
      const navType = 1
      const idSection = this.props.idSection;
      const timeExecuted = this.currentTime();
  
      recordTracking(timeStart, navItem, navType, idSection, timeExecuted).then(res => {
        this.setState({ shouldRecordTracking: false })
        if(getGamigication()) {
          const totalUserPoints = res.data.totalUserPoints
          this.props.updatePoints(totalUserPoints)
        }
      }).catch((err) => {
        console.log('error tracking', err)
      })
    }
  }

  ref = player => {
    this.player = player
  }
  
  trackStart() {
    this.setState({ timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss") })
  }

  render() {
    const { playing, isDesktop } = this.state;
    const { data } = this.props;
    updateFooterStatus(getDesign(this.props.idSection, "general")?.showBottomMenu || "true");
    updateHeaderStatus(getDesign(this.props.idSection, "general")?.showTopBar || "true");

    return (
      <>
        <ModalContinueMedia 
          startMedia={() => { this.startFromBeggining() }} 
          continueMedia={() => { this.continueFromLastTime() }} 
          mediaType='audio' 
          idParentSection={this.props.idSection}
          show={this.state.modal}
        />
        <IdleTimer
          ref={(ref) => {
            this.idleTimerRef = ref;
          }}
          timeout={this.state.timeRefresh}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <div className={style.wrapper} style={{ ...getFooterSize(), ...getDesign(this.props.idSection, "general") }}>
          {
            <div className={style.pageWrapper} >
              <div className={style.bodyContent}>
                <div className={style.bodyMedia}>
                  <img className={style.podcastImg} src={data.image} data-backdrop="false" style={{ width: "300px" }} alt="img" />
                </div>
                <div className={style.audioModule}>
                  <AudioInfo idSection={this.props.idSection} isDesktop={isDesktop} title={data.text} author={data.author}/>
                  <div className={style.bodyRange}>
                    <ReactPlayer
                      url={data.contentLocation}
                      playing={playing}
                      controls
                      height={80}
                      width={500}
                      ref={this.ref}
                      onPlay={() => this.handlePlay()}
                      onPause={() => this.handlePause()}
                    />
                  </div>
                  <div
                    className="modal-player-rating"
                    style={{
                      ...getFooterSize(),
                      ...getDesign(this.props.idSection, "itemText"),
                      padding: 0,
                    }}
                  >
                    <CompleteRating stars={this.props.data.rating} numberVotes={data.numberVotes} />
                    <CompleteRating id={data.idContentItem} userStar={data.vote} />
                  </div>
                </div>
                
              </div>
              <div className={style.podCastList}>
                <div className={style.podcastImgTitle} style={getDesign(this.props.idSection, "itemText",'fontFamily')} >
                  Ouça também
                </div>
                <PodcastList idSection={this.props.idSection} />
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  updatePoints: (item) => dispatch(updatePoints(item)),
});

export default connect(null, mapDispatchToProps)(ContentAudio);